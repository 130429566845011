const axios = require('axios').default;

class FormContacto{
    constructor(target){
        this.form = target;
        this.inputsRequired = this.form.querySelectorAll("input[required], textarea[required], select[required]");

        window.sendFormContacto = (data) => {
            this.sendForm(data);
        };
        window.iniFormContacto = this.iniFormContacto;
        this.form.addEventListener("submit", (e) => {
            e.preventDefault();
            grecaptcha.execute();
        });
    }

    iniFormContacto(){
    }

    sendForm(recaptchaData){
        
        let fdata = new FormData(this.form);

        var data = Array.from(
            fdata,
            e => e.map(encodeURIComponent).join('=')
        ).join('&')
        this.disableForm();
        axios.post(this.form.getAttribute("action"), data)
        .then(resp => {
            if(resp.status != 200 || resp.status != 201){
                this.showSuccess();
            }else{
                this.showError(resp.errorRes);
                this.enableForm();
            }
        })
        .catch(err => {
            console.error("ERROR: ",err);
            this.showError("Se produjo un error inesperado. Inténtelo más tarde.");
            this.enableForm();
        });
    }

    disableForm(){
        let elems = this.form.querySelectorAll("input, textarea, button");
        elems.forEach(element => {
            element.setAttribute("disabled", true);
        });
    }

    enableForm(){
        let elems = this.form.querySelectorAll("input, textarea, button");
        elems.forEach(element => {
            element.setAttribute("disabled", false);
        });
    }

    showSuccess(){
        let error = this.form.querySelector(".error");
        error.classList.remove("text-danger");
        error.classList.remove("d-none");
        error.classList.add("text-primary");
        error.innerText = "Gracias. Nos contactaresmo con usted a la brevedad.";
    }

    showError(msg){
        let error = this.form.querySelector(".error");
        error.classList.remove("d-none");
        error.innerText = msg;
    }

    hideError(){
        this.form.querySelector("error").classList.add("d-none");
    }
}
export default FormContacto;