class WidowUnmaker{

    constructor(selector, nextSelectorIgnore = "br", currSelectorIgnore = false){

        //minimum characters to be elgible for widow removal
        this.MIN_CHAR_COUNT = 27;
        // max characters to break to the next line
        this.MAX_NOWRAP_COUNT = 50;
        //desired amount of words to break to next line. If max chars reached, it will use less words.
        this.WORDS = 2;
        //selectors to icnore if next sibling matches them
        this.nextSelectorIgnore = nextSelectorIgnore;
        this.currSelectorIgnore = currSelectorIgnore;

        this.elems = [... document.querySelectorAll(selector)];
        this.textNodes = [];

        this.elems.forEach(item => {
            //match ignored
            if(currSelectorIgnore && item.matches(this.currSelectorIgnore)) return false;
            //find all text nodes
            let textnodes = [... item.childNodes].filter(item =>{
                if(item.nodeType == 3 && item.textContent.length > this.MIN_CHAR_COUNT){
                    return true;
                }else{
                    return false;
                }
            });
            this.textNodes.push.apply(this.textNodes, textnodes);
        });

        this.unmakeTheWidows();
    }

    unmakeTheWidows(){
        this.textNodes.forEach(item => {

            //ignore if the next sibling is a forced line break
            if(item.nextSibling && item.nextSibling.matches(this.nextSelectorIgnore)) return;

            let arrayed = item.nodeValue.split(" ");

            let wordsToWrap = [];
            let charcount = 0;
            for (let index = 0; index < this.WORDS; index++) {
                let popped = arrayed.pop();
                wordsToWrap.push(popped);
                if(charcount += popped.length > this.MAX_NOWRAP_COUNT) break;
            }
            wordsToWrap.reverse();

            //put it back together
            item.nodeValue = arrayed.join(" ")+" ";

            //create the new element
            let nowrap = document.createElement("span");
            nowrap.style.whiteSpace = "nowrap";
            nowrap.textContent = wordsToWrap.join(" ");

            //magic with siblings
            item.parentNode.insertBefore(nowrap, item.nextSibling);
        });
    }

    nexSiblingShouldBeIgnored(){

    }
}

export {WidowUnmaker};