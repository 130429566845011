import "../scss/global.scss";
import Glide from '@glidejs/glide';

import Menu from "./components/Menu";
import Popup from "./components/Popup";
import FormContacto from "./components/FormContacto";
import { WidowUnmaker } from './components/WidowUnmaker.js'
window.menu = new Menu();

// widow unmaker. Ignores forced line breaks and hard coded nowrpas
let wunmaker = new WidowUnmaker("p, h2, h3, h4, .lead, .small, strong", "br, span[syle='white-space: nowrap;']", ".productgal_item p");

//galerias hero
let slider = document.querySelector(".herogal");
if(slider){
    let slides = slider.querySelectorAll(".glide__slide");
    if(slides.length > 1){
        new Glide('.herogal', {
            type: 'carousel',
            gap: 0,
            autoplay: 5000,
            hoverpause: false
        }).mount();
    }else{
        slider.classList.add("herogal--single");
        new Glide('.herogal', {
            gap: 0,
            autoplay: 0,
            hoverpause: false
        }).mount();
    }
}

if(document.querySelector("form")){
    let form = new FormContacto(document.querySelector("form"));
}

const pop = new Popup();