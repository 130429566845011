import gsap from "gsap";
import Cookies from 'js-cookie';

class Popup{

    constructor(){
        console.log("POPUP");
        this.elem = document.querySelector(".popup");
        this.elemContent = document.querySelector(".popup_container");
        this.close = this.elem.querySelector(".popup_close");
        this.outsideClickListenerProxy = this.outsideClickListener.bind(this);
        this.addListeners();
        console.log(Cookies.get('popup'));
        if(!Cookies.get('popup') || Cookies.get('popup') != "1"){
            this.elem.style.display = "block";
        }
    }

    outsideClickListener(e){
        console.log(e, this.elemContent.contains(e.target));
        if(!this.elemContent.contains(e.target) && e.target != this.elemContent){
            this.closePopup();
        }
    }

    addListeners() {
        document.addEventListener('click', this.outsideClickListenerProxy);
        this.close.addEventListener('click', e => {this.closePopup(e); return false;});
    }

    closePopup(){
        gsap.to(this.elem,{y:"-10px", autoAlpha:0, duration: 0.3});
        document.removeEventListener('click', this.outsideClickListenerProxy)
        Cookies.set('popup', '1');
    }
    
}
export default Popup;